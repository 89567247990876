<template>
  <div>
    <div
      id="vikingtom-neutral"
      class="vikingtom"
      :class="isAnimatingNeutral ? 'opacity-1' : ''"
    />
    <div
      id="vikingtom-up"
      class="vikingtom"
      :class="isAnimatingUp ? 'animating-up' : ''"
    />
    <div
      id="vikingtom-down"
      class="vikingtom"
      :class="isAnimatingDown ? 'animating-down' : ''"
    />

    <!-- BOW -->
    <div
      id="vikingtom-bow-half"
      class="vikingtom"
      :class="isAnimatingBowHalf ? 'opacity-1' : ''"
    />
    <div
      id="vikingtom-bow-full"
      class="vikingtom"
      :class="isAnimatingBowFull ? 'opacity-1' : ''"
    />
  </div>
</template>
<script>
export default {
	name: "ScrollAnimation",
	data() {
		return {
			isAnimatingNeutral: false,
			isAnimatingUp: false,
			isAnimatingDown: false,
			isAnimatingBowHalf: false,
			isAnimatingBowFull: false,
			scrollTop: 0,
			setTimeoutNeutral: null,
			setTimeoutUp: null,
			setTimeoutDown: null,
		};
	},
	mounted() {
		this.scroll();
	},
	destroyed() {
		window.removeEventListener("scroll", null);
	},
	methods: {
		scroll() {
			window.addEventListener("scroll", () => {
				this.clearAll();
				console.log("Scrolling...");
				// this.animateNeutral();
				const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;

				if (newScrollTop > this.scrollTop) {
					// Scrolling down
					this.animateDown();
				} else {
					// Scrolling up
					this.animateUp();
				}
				this.scrollTop = newScrollTop <= 0 ? 0 : newScrollTop; // prevent negative scroll values
			});
		},
		clearAll() {
			this.isAnimatingNeutral = false;
			this.isAnimatingUp = false;
			this.isAnimatingDown = false;
			this.isAnimatingBowHalf = false;
			this.isAnimatingBowFull = false;
			clearTimeout(this.setTimeoutNeutral);
			clearTimeout(this.setTimeoutDown);
			clearTimeout(this.setTimeoutUp);
		},
		animateNeutral() {
			this.isAnimatingNeutral = true;

			this.setTimeoutNeutral = setTimeout(() => {
				this.isAnimatingNeutral = false;
				this.isAnimatingBowHalf = true;

				setTimeout(() => {
					this.isAnimatingBowHalf = false;
					this.isAnimatingBowFull = true;

					setTimeout(() => {
						this.isAnimatingBowFull = false;
						this.isAnimatingBowHalf = true;

						setTimeout(() => {
							this.isAnimatingBowHalf = false;
							this.isAnimatingNeutral = true;

							setTimeout(() => {
								this.isAnimatingNeutral = false;
							}, 500);
						}, 175);
					}, 500);
				}, 175);
			}, 500);
		},
		animateUp() {
			this.isAnimatingUp = true;

			this.setTimeoutUp = setTimeout(() => {
				this.isAnimatingUp = false;
				this.animateNeutral();
			}, 2500);
		},
		animateDown() {
			this.isAnimatingDown = true;

			this.setTimeoutDown = setTimeout(() => {
				this.isAnimatingDown = false;
				this.animateNeutral();
			}, 2500);
		}
	}
};
</script>

<style>
    .d-none {
        display: none;
    }

    .d-block {
        display: block;
    }

    .opacity-1 {
        opacity: 1 !important;
    }

    .vikingtom {
        position: fixed;
        content: "";
        height: 210px;
        width: 100px;
        opacity: 0;
        top: 45%;
        right: 2%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: contrast(1.3) drop-shadow(2px 4px 6px black);
    }

    #vikingtom-neutral {
        background-image: url("../assets/images/neutral.png");
    }

    #vikingtom-up {
        background-image: url("../assets/images/up.png");
    }

    #vikingtom-down {
        background-image: url("../assets/images/down.png");
    }

    #vikingtom-bow-half {
        background-image: url("../assets/images/bow-half.png");
    }

    #vikingtom-bow-full {
        background-image: url("../assets/images/bow-full.png");
    }

    .animating-neutral {
        animation: AnimateNeutral 20s;
    }

    .animating-up {
        animation: AnimateUp;
        animation-duration: 20s;
    }

    .animating-down {
        animation: AnimateDown 20s;
    }

    @keyframes AnimateNeutral {
        0% {
            opacity: 0;
            /* right: 100%; */
        }

        50% {
            /* right: 2%; */
        }

        100% {
            opacity: 1;
            /* right: 2%; */
        }
    }

    @keyframes AnimateUp {
        0% {
            opacity: 0;
        }

        3% {
            opacity: 1;
            top: 43%;
        }

        20% {
            top: 0%;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes AnimateDown {
        0% {
            opacity: 0;
        }

        2% {
            transform: rotate(5deg);
        }

        3% {
            opacity: 1;
            top: 43%;
            transform: rotate(0deg);
        }

        4% {
            top: 45%;
            transform: rotate(-5deg);
        }

        5% {
            top: 43%;
            transform: rotate(0deg);
        }

        6% {
            top: 45%;
            transform: rotate(5deg);
        }

        7% {
            top: 43%;
            transform: rotate(0deg);
        }

        100% {
            opacity: 1;
        }
    }
</style>
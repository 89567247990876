<template>
  <div id="app">
    <div class="vikingbot">
      <img src="./assets/images/vikingbot.png">
      <div class="eyes">
        <div
          id="eye1"
          class="eye"
        />
        <div
          id="eye2"
          class="eye"
        />
      </div>
    </div>
    <h1>VikingBot is {{ vikingbot ? "online 👌" : "offline 🤷‍♂️" }}</h1>
    <h2>Slack is {{ slackService ? "online ✔" : "offline 🤷‍♂️" }}</h2>
    <h2>Trading is {{ tradeService ? "online ✔" : "offline 🤷‍♂" }}</h2>
    <h3>v{{ version }}</h3>
    <SuperDuperCanvas />
    <div id="huge-height-yo" />
    <scroll-animation />
  </div>
</template>

<script>
import ScrollAnimation from "./components/ScrollAnimation.vue";
import SuperDuperCanvas from "./components/SuperDuperCanvas.vue";
import {
	version
} from "../package.json";
import axios from "axios";
export default {
	name: "App",
	components: {
		ScrollAnimation,
		SuperDuperCanvas
	},
	data() {
		return {
			version,
			vikingbot: null,
			slackService: null,
			tradeService: null
		};
	},
	mounted() {
		this.ping();
		this.eyes();
	},
	destroyed() {
		window.removeEventListener("scroll");
	},
	methods: {
		async ping() {
			try {
				// Check if our slack service works...
				await axios.get("/ping");
				this.vikingbot = true;
			} catch (e) {
				console.log("Unable to ping vikingbot...");
				this.vikingbot = false;
			}

			try {
				await axios.get("/ping/slack");
				this.slackService = true;
			} catch (e) {
				this.slackService = false;
				console.log("Unable to ping slack service...");
			}

			// Check if our trading service works...
		},
		eyes() {
			// Based off of Codewoofy's eyes follow mouse. It is just cleaned up, face removed, and then made to be a little more cartoony. https://codepen.io/Codewoofy/pen/VeBJEP
			const app = document.getElementById("app");
			app.addEventListener("mousemove", () => {
				const eyes = document.getElementsByClassName("eye");

				eyes.forEach(eye => {
					const x = (eye.getBoundingClientRect().left) + (eye.getBoundingClientRect().width / 2);
					const y = (eye.getBoundingClientRect().top) + (eye.getBoundingClientRect().height / 2);
					const rad = Math.atan2(event.pageX - x, event.pageY - y);
					const rot = (rad * (180 / Math.PI) * -1) + 180;
					eye.style["transform"] = `rotate(${rot}deg)`;
				});
			});
		}
	}
};
</script>

<style>
  html {
    height: 100%;
  }

  img {
    width: 260px;
  }

  body {
    background: linear-gradient(65deg, #6ba797, #7b0c0c, #2800ff);
    background-size: 600% 750%;
    animation: VikingBot 30s ease infinite;
  }

  @keyframes VikingBot {
    0% {
      background-position: 0% 20%
    }

    50% {
      background-position: 100% 81%
    }

    100% {
      background-position: 0% 20%
    }
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 200px;
    color: #fff;
    /* height: 80vh; */
  }

  .vikingbot {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .vikingbot img {
    height: 256px;
  }

  .eyes {
    position: absolute;
    top: 120px;
  }

  .eye {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background: #CCC;
  }

  .eye:after {
    /*pupil*/
    position: absolute;
    bottom: 12px;
    right: 9px;
    width: 13px;
    height: 13px;
    background: #000;
    border-radius: 50%;
    content: " ";
  }

  #eye1 {
    position: absolute;
    right: 10px;
    top: -6px;
  }

  #eye2 {
    position: absolute;
    left: 40px;
    top: -6px;
  }

  #huge-height-yo {
    height: 1300px;
  }

</style>
<template>
  <canvas id="my-super-duper-canvas" />
</template>

<script>
export default {
	mounted() {
		const canvas = document.querySelector("#my-super-duper-canvas");

		if(!canvas.getContext) console.error("no canvas context available");

		const context = canvas.getContext("2d");
		context.lineWidth = 10;
		context.beginPath(); 
		context.moveTo(0, 0);
		context.lineTo(0, 10);
		context.lineTo(10, 40);
		context.lineTo(40, 150);
		context.lineTo(150, 230);
		context.closePath();
		context.stroke();
	}
};
</script>

<style scoped>
#my-super-duper-canvas {
    width: 100%;
    height: 800px;
}
</style>